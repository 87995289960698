
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Session from '../../controllers/Session';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import SessionButtonsConstants from './SessionButtons.constants';
import './SessionButtons.css';

export default function SessionButtons(props) {
  const { barColor, isCet10CustomLogin } = props;
  const fontSize = selectResponsiveValue(SessionButtonsConstants.LogOutFontSizeResponsiveConfiguration);
  return (
    <div className='SessionButtonsContainer'>
      <Button
        className='SessionButton'
        onClick={Session.logOut}
        style={{ '--mainFontSize': fontSize, 'backgroundColor': barColor }}
      >
        {SessionButtonsConstants.LogOutText[isCet10CustomLogin ? 'cet10CustomLogin' : 'groupedUsersLogin']} </Button>
    </div>
  );
}
