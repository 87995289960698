
import React, { Component } from 'react';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import ItemsListConstants from './ItemsList.constants';
import './ItemsList.css';

class ItemsList extends Component  {
  constructor( props) {
    super(props);
    this.state = { activePage: 0 };
  }

  renderPaginationPrev( pages, buttonElement = false) {
    if( pages <= 1 || this.state.activePage === 0) return '';
    const Click = () => this.setState({ activePage: this.state.activePage - 1 });
    if( buttonElement) {
      return React.cloneElement( buttonElement, { onClick: Click })
    }
    const Text = selectResponsiveValue( ItemsListConstants.PreviousButtonResponsiveConfiguration);
    return <span className='paginationButton previous' onClick={Click}>{Text}</span>;
  }

  renderPaginationNext( pages, buttonElement = false) {
    if( pages <= 1 || this.state.activePage + 1 >= pages) return '';
    const Click = () => this.setState({ activePage: this.state.activePage + 1 });
    if( buttonElement) {
      return React.cloneElement( buttonElement, { onClick: Click })
    }
    const Text = selectResponsiveValue( ItemsListConstants.NextButtonResponsiveConfiguration);
    return <span className='paginationButton next' onClick={Click}>{Text}</span>;
  }

  renderPaginationButtons( pages) {
    if( pages <= 1) return '';
    const ActivePage = this.state.activePage + 1;
    return (
      <div className='paginationContainer'>
        { this.renderPaginationPrev( pages) }
        { ActivePage > 1 ? <span className='paginationButton' onClick={ () => this.setState({activePage: 0})}>{1}</span> : ''}
        { ActivePage > 4 ? <span className='paginationButton dots'>...</span> : ''}
        { ActivePage > 3 ? <span className='paginationButton' onClick={ () => this.setState({activePage: this.state.activePage - 2})}>{ActivePage - 2}</span> : ''}
        { ActivePage > 2 ? <span className='paginationButton' onClick={ () => this.setState({activePage: this.state.activePage - 1})}>{ActivePage - 1}</span> : ''}
        <span className='paginationButton current'>{ActivePage}</span>
        { ActivePage < pages - 1 ? <span className='paginationButton' onClick={ () => this.setState({activePage: this.state.activePage + 1})}>{ActivePage + 1}</span> : ''}
        { ActivePage < pages - 2 ? <span className='paginationButton' onClick={ () => this.setState({activePage: this.state.activePage + 2})}>{ActivePage + 2}</span> : ''}
        { ActivePage < pages - 3 ? <span className='paginationButton dots'>...</span> : ''}
        { ActivePage < pages ? <span className='paginationButton' onClick={ () => this.setState({activePage: pages - 1})}>{pages}</span> : ''}
        { this.renderPaginationNext( pages) }
      </div>
    );
  }

  render() {
    const PaginationStyles = ItemsListConstants.PaginationStyles;
    const length = this.props.width * this.props.height;
    const pages = Math.ceil( this.props.children.length / length);
    const offset = this.state.activePage * length;
    const rows = [];
    for( let i = 0; i < length; i += this.props.width) {
      rows.push( this.props.children.slice( offset + i, offset + i + this.props.width));
    }
    return (
      <div>
        { this.props.paginationStyle === PaginationStyles.Double || this.props.paginationStyle === PaginationStyles.Upper ? this.renderPaginationButtons( pages) : ''}
        { rows.map( (row,i) => {
          return (
            <div key={i} className='listRow'>
              { this.props.paginationStyle === PaginationStyles.InRow ? this.renderPaginationPrev( pages, this.props.buttonPrev) : '' }
              { row.map( (item,j) => {
                return (
                  <div key={j} className='listItem' style={{ '--itemsPerRow': this.props.width}}>
                    { item }
                  </div>
                );
              }) }
              { this.props.paginationStyle === PaginationStyles.InRow ? this.renderPaginationNext( pages, this.props.buttonNext) : '' }
            </div>
          );
        })}
        { this.props.paginationStyle === PaginationStyles.Double || this.props.paginationStyle === PaginationStyles.Bottom ? this.renderPaginationButtons( pages) : ''}
      </div>
    );
  }

  componentDidUpdate() {
    const length = this.props.width * this.props.height;
    const pages = Math.ceil( this.props.children.length / length);
    if( this.state.activePage >= pages) this.setState({ activePage: pages-1 });
  }

}

export default ItemsList;
