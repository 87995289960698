
import ExternalConfiguration, { DomainsKeys } from '../../external-configuration';

import Call from '../Network/Call';

export default Call;

export function getBackEndDomain() {
  const Environments = ExternalConfiguration.EnvDomains || {};
  const environment = Environments[process.env.NODE_ENV] || Environments['default'];
  return environment[DomainsKeys.BackEnd];
}

export function buildApiBasePath() {
  const BackEndDomain = getBackEndDomain();
  return `${BackEndDomain}${ExternalConfiguration.BackEndApiBasePath}`;
}
