
import MainConstants from '../../layouts/Main/Main.constants';
const { DistributionResponsiveWidths } = MainConstants;

const MenuSelectorConstants = {
  NumberOfElementsResponsiveConfiguration: [{
    FromWidth: DistributionResponsiveWidths.Smal,
    value: { width: 1, height: 15 },
  },{
    FromWidth: DistributionResponsiveWidths.Medium,
    value: { width: 2, height: 10 },
  },{
    FromWidth: DistributionResponsiveWidths.Big,
    value: { width: 3, height: 5 },
  }],
};

export default MenuSelectorConstants;
