export const sortIframes = (iframesList = []) => {

  iframesList.forEach(iframe => {
    const test0 = /[\d]{4}(-[\d]{1,2}){2}[\s]+[\d]{1,2}:[\d]{1,2}/.exec(iframe.name);
    if (test0) {
      iframe.date = new Date(test0[0].replace(/[\s]+/, ' '));
      if (!(iframe.date > new Date(0))) console.log('p1: ', test0);
    } else {
      const test1 = /[\d]{4}([-\.][\d]{1,2}){2}[\s]+[\d]{1,2}/.exec(iframe.name);
      if (test1) {
        iframe.date = new Date(`${test1[0].replace(/\./g, '-').replace(/[\s]+/, ' ')}:00`);
        if (!(iframe.date > new Date(0))) console.log('p2: ', test1);
      } else {
        const test2 = /[\d]{4}([-\.][\d]{1,2}){2}/.exec(iframe.name);
        if (test2) {
          iframe.date = new Date(`${test2[0].replace(/\./g, '-').replace(/[\s]+/, ' ')} 00:00`);
          if (!(iframe.date > new Date(0))) console.log('p3: ', test2);
        } else {
          // console.log( 'perdido: ', iframe.name);
          iframe.date = new Date(0);
        }
      }
    }
  });

  // console.log( 'sorting: ', iframesList);
  iframesList.sort((a, b) => b.date - a.date);
  // console.log( 'sorted: ', iframesList);
}

