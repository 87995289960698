
import {
  loginAndCreateSession,
  validateTokenAndCreateSession,
  revalidateSession,
  resetPassword,
  checkRecoverPasswordToken,
  updatePasswordGroupedUser,
  addGroupedUser,
  checkEmailActivationToken,
  activateGroupedUser
} from '../helpers/BackEnd/Session';

const BackEnd = {

  logIn: (hostname, email, password) => {
    return loginAndCreateSession(hostname, email, password);
  },

  validateToken: (hostname, token) => {
    return validateTokenAndCreateSession(hostname, token);
  },

  logOut: async (hostname, email, sessionId) => {
    // REVIEW call api to logout if sessions are stored in api
  },

  revalidate: (sessionId) => {
    return revalidateSession(sessionId);
  },

  resetPassword: (hostname, email, linkHostname) => {
    return resetPassword(hostname, email, linkHostname);
  },

  checkRecoverPasswordToken: (hostname, recoverPasswordToken) => {
    return checkRecoverPasswordToken(hostname, recoverPasswordToken)
  },

  updatePasswordGroupedUser: (hostname, recoverPasswordToken, newPasswordInput) => {
    return updatePasswordGroupedUser(hostname, recoverPasswordToken, newPasswordInput)
  },

  addGroupedUser: (hostname, user) => {
    return addGroupedUser(hostname, user)
  },

  checkEmailActivationToken: (hostname, activateUserToken) => {
    return checkEmailActivationToken(hostname, activateUserToken)
  },

  activateGroupedUser: (hostname, activateUserToken, password) => {
    return activateGroupedUser(hostname, activateUserToken, password)
  }

};

export default BackEnd;
