import React, { Component } from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/Login.css';
import '../NewRegister/NewRegister.css';
import Alert from '../../components/Alert/Alert';
import Session from '../../controllers/Session';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';

class NewRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            platformId: this.props.configuration.platformId,
            user: {
                name: '',
                email: '',
                password: '',
                clientCustomData: '',
                active: false,
                platform: { "id": this.props.configuration.platformId },
                groupedUserGroups: [{ "id": this.props.configuration.groupedUserGroup.id }],
            },
            passwordVisibility: false,
            show: false,
            alertMessage: '',
            status: null,
            token: this.props.RouteProps.match.params.token ? this.props.RouteProps.match.params.token : null,
            loading: false,
        }
        this.changeUserAtts = this.changeUserAtts.bind(this);
        this.newGroupedUser = this.newGroupedUser.bind(this);
        this.checkEmailActivationToken = this.checkEmailActivationToken.bind(this);
        this.activateUser = this.activateUser.bind(this);

    }
    changeUserAtts = (event) => {
        let { user } = this.state
        user[event.target.name] = event.target.value
        this.setState({ user })
    };
    async newGroupedUser() {
        const user = this.state.user;
        this.setState({ loading: true });
        if (user.name && user.email) {
            try {
                const response = await Session.addGroupedUser(user);
                this.setState({ show: true })
                this.setState({ alertMessage: `Success: ${response.message}`, status: response.status });
            } catch (error) {
                this.setState({ show: true })
                if (error.message) this.setState({ alertMessage: `Error: ${error.message}` });
                else this.setState({ alertMessage: `Error: Internal Error.` });
            }
            this.setState({ loading: false });
            this.setState({ user: { name: '', email: '', password: '' } })
        } else {
            this.setState({ show: true })
            this.setState({ loading: false });
            this.setState({ alertMessage: 'Error: Missing user name or e-mail.' })
        }
    };
    async checkEmailActivationToken(token) {
        try {
            const isActivationTokenValid = await Session.checkEmailActivationToken(token);
            if (isActivationTokenValid.status !== 200) this.setState({ show: true, alertMessage: `Error: ${isActivationTokenValid.message}` });
        } catch (error) {
            this.setState({ show: true });
            this.setState({ alertMessage: `Error: ${error.message}` });
        }
    };
    async activateUser() {
        const token = this.state.token;
        const password = this.state.user.password;
        this.setState({ loading: true });
        try {
            const response = await Session.activateGroupedUser(token, password);
            this.setState({ show: true })
            this.setState({ status: response.status, alertMessage: response.message });
        } catch (error) {
            console.log(error);
            this.setState({ show: true })
            if (error.message) this.setState({ alertMessage: `Error: ${error.message}` });
            else this.setState({ alertMessage: `Error: Internal Error.` });
        }
        this.setState({ loading: false });
    }

    render() {
        const onKeyDown = event => {
            if (event.key === 'Enter') { !this.state.token ? this.newGroupedUser() : this.activateUser() }
        };
        const closeAlert = () => {
            this.setState({ show: false })
            if (this.state.status === 200 || (this.state.token !== null && this.state.status !== 400)) { window.location.pathname = '/' }
        }
        const logoSrc = this.props.configuration.logo ? this.props.configuration.logo.url : null;
        const loginBackground = this.props.configuration.background ? this.props.configuration.background.url : null;
        const navBarColor = this.props.configuration.options.menuType.color ? this.props.configuration.options.menuType.color : null;
        return (
            <div className='LoginFormContainer' style={{ 'backgroundImage': `url(${loginBackground})`, 'backgroundSize': 'cover' }}>
                {this.state.show && <Alert message={this.state.alertMessage} show={closeAlert} />}
                <div className='LoginForm-centered'>
                    <Row>
                        {logoSrc ? <img className='LoginForm-logo w-100 h-100' src={logoSrc} /> : ''}
                    </Row>
                    <div className='LoginForm-background' style={{ 'backgroundColor': 'rgb(5,64,92)' }} >
                        <Col as='h1' className='text-center' style={{ 'color': 'rgb(0,155,222)' }}>
                            {!this.state.token ? 'REGISTRATION' : 'USER ACTIVATION'}
                        </Col>
                        {
                            !this.state.token
                                ? <>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>NAME</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='form-control'
                                            onKeyDown={onKeyDown}
                                            placeholder='User Name'
                                            name='name'
                                            value={this.state.user.name}
                                            onChange={this.changeUserAtts}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>E-MAIL</Form.Label>
                                        <Form.Control
                                            type='email'
                                            className='form-control'
                                            onKeyDown={onKeyDown}
                                            placeholder='E-mail'
                                            name='email'
                                            value={this.state.user.email}
                                            onChange={this.changeUserAtts}
                                        />
                                    </Form.Group>
                                </>
                                : <Form.Group className="mb-3" >
                                    <Form.Label>NEW PASSWORD</Form.Label>
                                    <div className='password-input'>
                                        <Form.Control
                                            type={this.state.passwordVisibility ? "text" : "password"}
                                            className='form-control'
                                            onKeyDown={onKeyDown}
                                            placeholder='Password'
                                            name='password'
                                            value={this.state.user.password}
                                            onChange={this.changeUserAtts}
                                        />
                                        <Button
                                            variant='light'
                                            onClick={() => this.setState({ passwordVisibility: !this.state.passwordVisibility })}
                                        >
                                            {
                                                !this.state.passwordVisibility ?
                                                    <i className="far fa-eye-slash" />
                                                    :
                                                    <i className="far fa-eye" />
                                            }
                                        </Button>
                                    </div>
                                </Form.Group>
                        }
                        <div className='LoginForm-buttons d-flex-row text-center'>
                            <Button
                                style={{ 'backgroundColor': navBarColor, 'fontWeight': 'bold' }}
                                type='submit'
                                className='btn btn-primary px-4 my-3'
                                onClick={!this.state.token ? this.newGroupedUser : this.activateUser}
                            >
                                {!this.state.token ? 'VERIFY ACCOUNT' : 'ACTIVATE'}
                            </Button>
                            <Button
                                style={{ 'color': 'rgb(0,155,222)' }}
                                variant='link'
                                className='btn-resend btn-cancelInForm w-100'
                                onClick={() => window.location.pathname = '/'}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </div>
                {this.state.loading ? <LoadingAnimation /> : ''}
            </div >
        )
    }

    componentDidMount() {
        if (this.state.token !== null) this.checkEmailActivationToken(this.state.token);
    }
}

export default NewRegister;