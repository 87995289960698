
import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import MainPlayerConstants from './MainPlayer.constants';
import './MainPlayer.css';

export default function MainPlayer({ RouteProps, pageOptions, iframe, fontDesign }) {
  const fontSize = selectResponsiveValue(MainPlayerConstants.NoContentFontSizeResponsiveConfiguration);
  if (!iframe) return (
    <div className='noContent' style={{ '--noContentFontSize': fontSize, '--mainFont': fontDesign.font }}>
      <span> {MainPlayerConstants.NoContentText} </span>
    </div>
  );
  const { match: { params: { autoplay } } } = RouteProps;
  //const src = autoplay === 'autoplay' ? `${iframe.url}?autoplay` : iframe.url; //TODO con el player default
  const src = autoplay === 'autoplay' ? `${iframe.urls.legacy}?autoplay` : iframe.urls.legacy; 

  return (
    <div className={pageOptions.withMainPlayerBackground ? 'backgroundMainPlayer' : ''}>
      <div className='mainPlayerContainer'>
        <iframe
          id='mainPlayerIframe'
          src={src}
          title='Main Player'
          scrolling='no'
          allowFullScreen='allowfullscreen'
          allow='autoplay; fullscreen'
        />
      </div>
    </div>
  );
}
