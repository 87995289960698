
// import { checkStatus, networkErrorJSONParser } from '../utils';

// Headers
const DefaultHeaders = {
  'Content-Type': 'application/json; charset=UTF-8',
};

function throwOnNotOk( response) {
  if( !response.ok) throw response;
  return response;
}

async function tryParseJsonOnNetworkError( error) {
  try {
    if( error.json) error = await error.json();
  } catch( metaError) {
    console.error( new Date(), 'Error helpers::Network::Call::tryParseJsonOnNetworkError - metaError: ', metaError);
  };
  throw error;
}

export default async function Call( url, fetchOptions = {}, { parseResponse = true } = {}) {
  const BodiedMethods = ['POST','PUT'];
  try {
    if( !fetchOptions) fetchOptions = {};
    if( !fetchOptions.headers) fetchOptions.headers = {};
    fetchOptions.headers = { ...DefaultHeaders, ...fetchOptions.headers };
    if( BodiedMethods.indexOf(fetchOptions.method) > -1) {
      if( !fetchOptions.body) fetchOptions.body = {};
      fetchOptions.body = JSON.stringify(fetchOptions.body);
    }
    const response = await fetch( url, fetchOptions).then( throwOnNotOk).catch( tryParseJsonOnNetworkError);
    if( !parseResponse) return response.status !== 204 ? response.text() : '';
    return response.status !== 204 ? response.json() : {};
  } catch (error) {
    console.error( new Date(), 'Error helpers::Network::Call - error: ', error);
    throw error;
  }
}
Call.head = (url, fetchOptions, callOptions) => Call( url, { ...fetchOptions, method: 'HEAD' }, callOptions);
Call.get = (url, fetchOptions, callOptions) => Call( url, { ...fetchOptions, method: 'GET' }, callOptions);
Call.post = (url, fetchOptions, callOptions) => Call( url, { ...fetchOptions, method: 'POST' }, callOptions);
Call.put = (url, fetchOptions, callOptions) => Call( url, { ...fetchOptions, method: 'PUT' }, callOptions);
Call.delete = (url, fetchOptions, callOptions) => Call( url, { ...fetchOptions, method: 'DELETE' }, callOptions);
