
import MainConstants from '../../layouts/Main/Main.constants';
const { TextResponsiveWidths, DistributionResponsiveWidths } = MainConstants;

const BottomSectionConstants = {
  IframesDistributionResponsiveConfiguration: [{
    FromWidth: DistributionResponsiveWidths.Small,
    value: { width: 1, height: 6 },
  },{
    FromWidth: DistributionResponsiveWidths.Medium,
    value: { width: 2, height: 6 },
  },{
    FromWidth: DistributionResponsiveWidths.Big,
    value: { width: 3, height: 4 },
  }],
  NumberOfLastUpdatedResponsiveConfiguration: [{
    FromWidth: DistributionResponsiveWidths.Small,
    value: { width: 1, height: 3 },
  },{
    FromWidth: DistributionResponsiveWidths.Medium,
    value: { width: 3, height: 2 },
  }],
  NumberOfMostViewedResponsiveConfiguration: [{
    FromWidth: DistributionResponsiveWidths.Small,
    value: { width: 1, height: 3 },
  },{
    FromWidth: DistributionResponsiveWidths.Medium,
    value: { width: 3, height: 2 },
  }],
  TextFontSizeResponsiveConfiguration: [{
    FromWidth: TextResponsiveWidths.Smal,
    value: '10px',
  },{
    FromWidth: TextResponsiveWidths.Medium,
    value: '12px',
  }],
  TitleFontSizeResponsiveConfiguration: [{
    FromWidth: TextResponsiveWidths.Smal,
    value: '12px',
  },{
    FromWidth: TextResponsiveWidths.Medium,
    value: '14px',
  }],
  CreationDateText: 'Fecha de creación: ',
};

export default BottomSectionConstants;
