

export const DomainsKeys = {
  BackEnd: 'BackEnd',
  CMS: 'CMS',
  Configuration: 'Configuration',
  Player: 'Player',
  Calendar: 'Calendar',
};

const ExternalConfiguration = {
  EnvDomains: {
    development: {
      [DomainsKeys.BackEnd]: 'https://calendar.codev8.info',
      [DomainsKeys.CMS]: 'https://maquina.codev8.net',
      [DomainsKeys.Configuration]: 'https://maquina.codev8.net',
      [DomainsKeys.Player]: 'https://maquina.codev8.net',
      [DomainsKeys.Calendar]: 'https://maquina.codev8.net',
    },
    default: {
      [DomainsKeys.BackEnd]: '',
      [DomainsKeys.CMS]: '//cms.codev8.net',
      [DomainsKeys.Configuration]: '//cdnapi.codev8.net',
      [DomainsKeys.Player]: '//cdnapi.codev8.net',
      [DomainsKeys.Calendar]: '//cdnapi.codev8.net',
    },
  },
  BackEndApiBasePath: '/api/v1.0',
  CMSApiBasePath: '/apicloud/v1.0',
  PlayerPath: '/vdmplayer',
  CalendarPath: '/custom/event-calendar',
  RedirectKey: 'redirect',
  TokenKey: 'Login',
  optionalDemoSections: {
    demoChat: [ 'webtv3.codev8.info', 'demomario.codev8.info', 'localhost.codev8.net', 'localhost'],
    demoMulti: [ 'comercial.codev8.info', 'webtv2.codev8.info', 'webtv3.codev8.info', 'demomario.codev8.info', 'localhost.codev8.net', 'localhost'],
  },
  noImageUrl: '/resources/no-image.png',
  sessionRevokedText: 'Parece que has revocado tu sesión, posiblemente entrando desde otro dispositivo o navegador, para volver a acceder al contenido, por favor vuelve a introducir tus credenciales.',
};

export default ExternalConfiguration;
