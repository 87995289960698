
import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import TopMenuConstants from './TopMenu.constants';

const TopMenuResponsive = {

  getNumberOfMenus: (fontSize, maxTextLengthPerMenu) => {
    const adjustment = maxTextLengthPerMenu / 10.0;   // NOTE factor de ajuste para poder usar los valores definidos en configurations con valores de maxTextLengthPerMenu distintos de 10
    const configurations = TopMenuConstants.NumberOfElementsResponsiveConfigurationsByFontSize[fontSize];
    return selectResponsiveValue( configurations.map( ({ BaseFromWidth, value }) => ({ FromWidth: BaseFromWidth*adjustment, value }) ));
  },

};

export default TopMenuResponsive;
