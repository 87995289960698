
const MainConstants = {
  TextResponsiveWidths: {
    Small: 0,
    Medium: 550,
    Big: 900,
  },
  DistributionResponsiveWidths: {
    Small: 0,
    Medium: 500,
    Big: 1000,
  },
  MainColumnWidthResponsiveConfiguration: [{
    FromWidth: 0,
    value: 1.0,
  },{
    FromWidth: 500,
    value: 0.8,
  },{
    FromWidth: 1000,
    value: 0.65,
  }],
};

export default MainConstants;
