
import MainConstants from '../../layouts/Main/Main.constants';
const { TextResponsiveWidths } = MainConstants;

const SessionButtonsConstants = {
  LogOutFontSizeResponsiveConfiguration: [{
    FromWidth: TextResponsiveWidths.Small,
    value: '9px',
  }, {
    FromWidth: TextResponsiveWidths.Medium,
    value: '13px',
  }, {
    FromWidth: TextResponsiveWidths.Big,
    value: '15px',
  }],
  LogOutText: {
    'cet10CustomLogin': 'Salir',
    'groupedUsersLogin': 'LOGOUT'
  }
}

export default SessionButtonsConstants;
