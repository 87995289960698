
const ItemsListConstants = {
  PreviousButtonResponsiveConfiguration: [{
    FromWidth: 0,
    value: ' < ',
  },{
    FromWidth: 740,
    value: ' Pagina anterior ',
  }],
  NextButtonResponsiveConfiguration: [{
    FromWidth: 0,
    value: ' > ',
  },{
    FromWidth: 740,
    value: ' Pagina siguiente ',
  }],
  PaginationStyles: {
    Double: 'double',
    Upper: 'upper',
    InRow: 'in_row',
    Bottom: 'bottom',
  },
};

export default ItemsListConstants;
