
import ExternalConfiguration, { DomainsKeys } from '../../external-configuration';

import Call from '../Network/Call';

export default Call;

export function getDomain( domainKey = DomainsKeys.CMS) {
  const Environments = ExternalConfiguration.EnvDomains || {};
  const environment = Environments[process.env.NODE_ENV] || Environments['default'];
  return environment[domainKey];
}

export function buildApiBasePath( domainKey = DomainsKeys.CMS) {
  const Domain = getDomain( domainKey);
  return `${Domain}${ExternalConfiguration.CMSApiBasePath}`;
}
