
import { Component } from 'react';

import { SendIframeProps } from 'react-iframe-component';

import ExternalConfiguration, { DomainsKeys } from '../../external-configuration';

import './Calendar.css';

const getCalendarDomain = () => {
  const Environments = ExternalConfiguration.EnvDomains || {};
  const environment = Environments[process.env.NODE_ENV] || Environments['default'];
  return environment[DomainsKeys.Calendar];
};

export default class Calendar extends Component {

  constructor( props) {
    super( props);
    this.state = {
      height: '0px',
    };
    this.childMessageHandler = this.childMessageHandler.bind( this);
  }

  childMessageHandler( message) {
    if( message.updateHeightEvent) {
      this.setState({ height: `${message.updateHeightEvent.height+100}px` });
    }
  }

  render() {
    return (
      <div style={{ '--calendarIframeHeight': this.state.height }}>
        <SendIframeProps
          className='calendarIframe'
          src={`${getCalendarDomain()}${ExternalConfiguration.CalendarPath}/${this.props.calendarId}`}
          propsToSend={{ }}
          childMessageHandler={ this.childMessageHandler }
        />
      </div>
    );
  }

}
