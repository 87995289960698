
import React from 'react';
import { Component } from 'react';

import '../ItemsList/extra.css';
import ItemsList from '../ItemsList/ItemsList';

import './ProgrammationGrid.css';

export default class ProgrammationGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programmationLoaded: false,
            programmationInfo: {},
            playlist: ""
        };
        this.getMainPlayerProgrammation = this.getMainPlayerProgrammation.bind(this);
        this.reciveProgrammationEventHandler = this.reciveProgrammationEventHandler.bind(this);
        this.requestMainPlayerProgrammation = this.requestMainPlayerProgrammation.bind(this);
        this.getProgrammationToken = 'mainPlayerGetProgrammation';
    }

    componentDidMount() {
        this.getMainPlayerProgrammation();
    }

    componentDidUpdate() {
        this.getMainPlayerProgrammation();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.reciveProgrammationEventHandler, false);
    }

    requestMainPlayerProgrammation() {
        if (!this.state.programmationLoaded) {
            const iframe = document.getElementById('mainPlayerIframe');
            if (iframe) {
                iframe.contentWindow.postMessage({
                    operation: 'getPlaylistInfo',
                    token: this.getProgrammationToken,
                }, '*');
                setTimeout(this.requestMainPlayerProgrammation, 100);
            }
        }
    }

    nextElementStartTime = (mode, playlist, i, startTime, finalDate, effectiveTimeStampStart) => {
        let next = new Date(startTime).getTime() + parseInt(playlist[i].duration, 10) * 1000;
        switch (mode) {
            case 'diary':
                if (i + 1 >= playlist.length) {
                    next = new Date(effectiveTimeStampStart);
                    while (next <= startTime) {
                        next.setDate(next.getDate() + 1);
                    }
                }
            // falls through
            case 'continualLoop':
                if (next >= new Date().getTime() + 24 * 60 * 60 * 1000) {
                    return;
                }
                break;
            case 'simple':
                break;
            default:
                return;
        }
        if (next >= finalDate) {
            return;
        }
        return next;
    }

    setStartTimes = ({ playlist, currentElement, effectiveTimeStampStart, finalDate, mode }) => {
        let startTime = effectiveTimeStampStart.getTime();
        let extendedPlaylist = [];
        for (let i = 0; startTime !== undefined; i++) {
            const ri = i % playlist.length;
            extendedPlaylist.push(Object.assign({}, playlist[ri]));
            extendedPlaylist[i].startTime = startTime;
            startTime = this.nextElementStartTime(mode, playlist, ri, startTime, finalDate, effectiveTimeStampStart);
        }
        extendedPlaylist[currentElement].currentElement = true;
        return extendedPlaylist;
    }

    reciveProgrammationEventHandler(event) {
        if (event.data.token === this.getProgrammationToken && event.data.currentElement !== undefined) {
            this.setState({ programmationLoaded: true, programmationInfo: event.data, playlist: this.setStartTimes(event.data) });
        }
    }

    getMainPlayerProgrammation() {
        const { menu } = this.props;
        const pageOptions = menu.pageOptions && menu.pageOptions.withProgrammationGrid;
        if (pageOptions && !this.state.programmationLoaded) {
            window.addEventListener('message', this.reciveProgrammationEventHandler, false);
            setTimeout(this.requestMainPlayerProgrammation, 0);
        }
    }

    selectElementsByWidth(elementsByWidth) {
        let elements = 1;
        elementsByWidth.forEach((width) => {
            if (window.innerWidth >= width[0]) {
                elements = width[1];
            }
        });
        return elements;
    }

    render() {
        const { menu } = this.props;
        const { programmationLoaded, programmationInfo, playlist } = this.state;

        const elements = this.selectElementsByWidth([[675, 2], [1000, 3], [1325, 4], [1650, 5],]);

        if (playlist.length > programmationInfo.currentElement + 1) {
            const updateProgrammationInfo = Object.assign({}, programmationInfo, { currentElement: programmationInfo.currentElement + 1 });
            setTimeout(() => this.setState({ programmationInfo: updateProgrammationInfo }),
                new Date(playlist[programmationInfo.currentElement + 1].startTime) - new Date()
            );
        }
        const extractTitle = (url) => {
            return menu.iframes[0].playlist.medias.filter(e => e.media.progressive === url)[0].media.title
        }

        return (
            <div className={'programmationGridContainer'}>
                {
                    programmationInfo.effectiveTimeStampStart && programmationLoaded ?
                        <>
                            <ItemsList width={elements} height={1} paginationStyle={'bottom'}>
                                {playlist.slice(programmationInfo.currentElement).map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <div className={'listItemAspect'}>
                                                <img alt='' src={item.image} />
                                                <div className={`hour ${item.currentElement ? 'currentHour' : ''}`}>
                                                    <i></i>
                                                    <span>{new Date(item.startTime).toLocaleTimeString()}</span>
                                                </div>
                                            </div>
                                            <br />
                                            <span className={'text title'} dangerouslySetInnerHTML={{ __html: extractTitle(item.progressive)}}/>
                                        </div>
                                    );
                                })}
                            </ItemsList> 
                        </>
                        : ""
                }
            </div>
        );
    }
}
