import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/Login.css';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import Session from '../../controllers/Session';
import Alert from '../../components/Alert/Alert';


const RecoverPassword = (props) => {

    const { token, configuration } = props;

    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState();
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        checkRecoverPasswordToken();
    }, []);

    const checkRecoverPasswordToken = async () => {
        try {
            const isRecoverTokenValid = await Session.checkRecoverPasswordToken(token);
            if (isRecoverTokenValid.status !== 200) {
                setShow(true);
                setStatus(isRecoverTokenValid.status)
                setAlertMessage(`Error: ${isRecoverTokenValid.message}`);
            }
        } catch (error) {
            setShow(true);
            setAlertMessage(`Error: ${error.message}`);
        }
    }

    const submitPassword = async () => {
        setLoading(true);
        setShow(true);
        if (newPassword === repeatPassword) {
            try {
                const response = await Session.updatePasswordGroupedUser(token, newPassword);
                setAlertMessage(`Success: ${response.message}`);
                setStatus(response.status);
            } catch (error) {
                if (error.message) setAlertMessage(`Error: ${error.message}`);
                else setAlertMessage(`Error: Internal Error.`);
            }
        } else {
            setAlertMessage('Error: Passwords are not equal.');
            setNewPassword('');
            setRepeatPassword('');
        }
        setLoading(false);
    }

    const closeAlert = () => {
        setShow(false);
        if (status === 200 || status === 401) { window.location.pathname = '/' }
    }

    const onKeyDown = event => {
        if (event.key === 'Enter') submitPassword();
    };

    const logoSrc = configuration.logo ? configuration.logo.url : null;
    const loginBackground = configuration.background ? configuration.background.url : null
    const navBar = configuration.options.menuType.color ? configuration.options.menuType.color : 'rgb(0,155,222)';
    return (
        <>
            <div className='LoginFormContainer' style={{ 'backgroundImage': `url(${loginBackground})`, 'backgroundSize': 'cover' }}>
                {show && <Alert message={alertMessage} show={closeAlert} />}
                <div className='LoginForm-centered'>
                    <Row>
                        {logoSrc ? <img className='LoginForm-logo w-100 h-100' src={logoSrc} /> : ''}
                    </Row>
                    <div className='LoginForm-background' style={{ 'backgroundColor': 'rgb(5,64,92)' }} >
                        <Col as='h1' className='text-center' style={{ 'color': 'rgb(0,155,222)' }}>
                            RESET PASSWORD
                        </Col>
                        < Form.Group className="mb-3" >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                className='form-control'
                                type='password'
                                onKeyDown={onKeyDown}
                                value={newPassword}
                                onChange={event => setNewPassword(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Repeat Password</Form.Label>
                            <Form.Control className='form-control'
                                type='password'
                                onKeyDown={onKeyDown}
                                value={repeatPassword}
                                onChange={event => setRepeatPassword(event.target.value)}
                            />
                        </Form.Group>
                        <div className='LoginForm-buttons d-flex-row text-center'>
                            <Button
                                style={{ 'backgroundColor': navBar, 'fontWeight': 'bold' }}
                                type='submit'
                                className='btn btn-primary px-4 my-3'
                                onClick={submitPassword}
                            >
                                RESET
                            </Button>
                            <Button
                                style={{ 'color': 'rgb(0,155,222)' }}
                                variant='link'
                                className='btn-resend btn-cancelInForm w-100'
                                onClick={() => window.location.pathname = '/'}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </div>
                {loading && <LoadingAnimation />}
            </div >
        </>
    )
}

export default RecoverPassword;