import BackEnd from './BackEnd';
import Configuration from './Configuration';

export const RevalidateSessionIntervalMS = 10000;   // NOTE 10 segundos
export const States = {
  LoggedIn: 'LoggedIn',
  LoggedOut: 'LoggedOut',
  Revalidated: 'Revalidated',
  Revoked: 'Revoked',
  Loading: 'Loading',
};

const Session = {

  hostname: window.location.hostname,
  sessionStorage: window.sessionStorage,
  sessionStorageKey: 'SessionInfo',
  updateSessionInfo: null,
  revalidateSessionInterval: null,

  logIn: async (email, password) => {
    Session.clearRevalidateSessionInterval();
    Session.sessionStorage.removeItem(Session.sessionStorageKey);
    const sessionInfo = await BackEnd.logIn(Session.hostname, email, password)
    Session.sessionStorage.setItem(Session.sessionStorageKey, JSON.stringify(sessionInfo));
    if (Session.updateSessionInfo) Session.updateSessionInfo({ sessionState: States.LoggedIn, sessionInfo });
    Session.startRevalidateSessionInterval();
    return sessionInfo;
  },

  logOut: async () => {   // TODO comunicar al servidor para que pueda borrar la sesion
    Session.clearRevalidateSessionInterval();
    Session.sessionStorage.removeItem(Session.sessionStorageKey);
    if (Session.updateSessionInfo) Session.updateSessionInfo({ sessionState: States.LoggedOut, sessionInfo: null });
  },

  validateToken: async (token) => {
    Session.clearRevalidateSessionInterval();
    Session.sessionStorage.removeItem(Session.sessionStorageKey);
    const sessionInfo = await BackEnd.validateToken(Session.hostname, token);
    Session.sessionStorage.setItem(Session.sessionStorageKey, JSON.stringify(sessionInfo));
    if (Session.updateSessionInfo) Session.updateSessionInfo({ sessionState: States.LoggedIn, sessionInfo });
    Session.startRevalidateSessionInterval();
    return sessionInfo;
  },

  revalidateSession: async (onlyUpdateOnFail) => {
    try {
      const sessionInfo = JSON.parse(Session.sessionStorage.getItem(Session.sessionStorageKey));
      const session = await BackEnd.revalidate(sessionInfo.sessionId);
      if (!onlyUpdateOnFail && Session.updateSessionInfo) Session.updateSessionInfo({ sessionState: States.Revalidated, sessionInfo });
      return session;
    } catch (error) {
      Session.clearRevalidateSessionInterval();
      Session.sessionStorage.removeItem(Session.sessionStorageKey);
      if (Session.updateSessionInfo) Session.updateSessionInfo({ sessionState: States.Revoked, sessionInfo: null });
    }
  },

  startRevalidateSessionInterval: () => {
    Session.clearRevalidateSessionInterval();
    Session.revalidateSessionInterval = setInterval(() => Session.revalidateSession(true), RevalidateSessionIntervalMS);
  },

  clearRevalidateSessionInterval: () => {
    if (Session.revalidateSessionInterval) clearInterval(Session.revalidateSessionInterval);
  },

  verifySession: async (updateSessionInfoCallback) => {
    Session.updateSessionInfo = updateSessionInfoCallback;
    const session = await Session.revalidateSession();
    if (session) Session.startRevalidateSessionInterval();
  },

  resetPassword: (email, isCet10CustomLogin) => {
    const linkHostname = isCet10CustomLogin ? null : window.location.host;
    return BackEnd.resetPassword(Session.hostname, email, linkHostname);
  },

  checkRecoverPasswordToken: (recoverPasswordToken) => {
    return BackEnd.checkRecoverPasswordToken(Session.hostname, recoverPasswordToken)
  },

  updatePasswordGroupedUser: (recoverPasswordToken, newPasswordInput) => {
    return BackEnd.updatePasswordGroupedUser(Session.hostname, recoverPasswordToken, newPasswordInput)
  },

  addGroupedUser: (user) => {
    return BackEnd.addGroupedUser(Session.hostname, user)
  },

  checkEmailActivationToken: (activateUserToken) => {
    return BackEnd.checkEmailActivationToken(Session.hostname, activateUserToken)
  },

  activateGroupedUser: (activateUserToken, password) => {
    return BackEnd.activateGroupedUser(Session.hostname, activateUserToken, password)
  }

};

export default Session;
