
import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import ExternalConfiguration from '../../external-configuration';

import Session from '../../controllers/Session';

import './Login.css';
import LoginCet10 from './LoginCet10';
import LoginGroupedUser from './LoginGroupedUser';
import { isCet10 } from '../../helpers/CustomClientSpecific/cet10/Configuration';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailInput: '',
      passwordInput: '',
      loading: false,
      alertMessage: '',
      show: false,
      isCet10CustomLogin: this.props.configuration.webtvCustomLogin
        ? this.props.configuration.webtvCustomLogin.id === "b04eca71-de9d-47cd-959f-2a1e2752b9e2"
        : null
    };
    this.emailInputRef = React.createRef();

    this.logIn = this.logIn.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  async logIn() {
    const RouteProps = this.props.RouteProps;
    const email = this.state.emailInput;
    const password = this.state.passwordInput;
    this.setState({ loading: true });
    try {
      const sessionInfo = await Session.logIn(email, password);
      const encodedRedirect = new URLSearchParams(RouteProps.location.search).get(ExternalConfiguration.RedirectKey);
      const redirect = encodedRedirect ? JSON.parse(encodedRedirect) : '/';
      RouteProps.history.push(redirect);
    } catch (error) {
      this.setState({ show: true })
      if (error.message) this.setState({ alertMessage: `Error: ${error.message}` });
      else this.setState({ alertMessage: `Error: Internal Error.` });
      this.setState({ loading: false });
    }
  }

  async resetPassword() {
    const email = this.state.emailInput;
    const isCet10CustomLogin = this.state.isCet10CustomLogin;
    this.setState({ loading: true });
    try {
      const response = await Session.resetPassword(email, isCet10CustomLogin);
      this.setState({ show: true });
      this.setState({ alertMessage: `Success: ${response.message}` });
    } catch (error) {
      this.setState({ show: true });
      if (error.message) this.setState({ alertMessage: `Error: ${error.message}` });
      else this.setState({ alertMessage: `Error: Internal Error.` });
    }
    this.setState({ loading: false });
  }

  render() {
    const emailInputOnChange = event => {
      this.setState({ emailInput: event.target.value });
    };
    const passwordInputOnChange = event => {
      this.setState({ passwordInput: event.target.value });
    };
    const onKeyDown = event => {
      if (event.key === 'Enter') this.logIn();
    };
    const closeAlert = () => {
      this.setState({ show: false })
    }
    const shortLogoSrc = this.props.configuration.shortLogo ? this.props.configuration.shortLogo.url : null;
    const logoSrc = this.props.configuration.logo ? this.props.configuration.logo.url : null;
    const loginBackground = this.props.configuration.background ? this.props.configuration.background.url : null;
    const navBarColor = this.props.configuration.options.menuType.color ? this.props.configuration.options.menuType.color : null;
    const withRegister = this.props.configuration.options.withRegister ? this.props.configuration.options.withRegister : false;
    return (
      this.state.isCet10CustomLogin
        ? <LoginCet10 shortLogoSrc={shortLogoSrc}
          alertMessage={this.state.alertMessage} show={this.state.show} loading={this.state.loading} emailInputRef={this.emailInputRef}
          logIn={this.logIn} resetPassword={this.resetPassword}
          emailInputOnChange={emailInputOnChange} passwordInputOnChange={passwordInputOnChange} onKeyDown={onKeyDown} closeAlert={closeAlert} />
        : <LoginGroupedUser logoSrc={logoSrc} loginBackground={loginBackground} navBarColor={navBarColor}
          alertMessage={this.state.alertMessage} show={this.state.show} loading={this.state.loading} emailInputRef={this.emailInputRef}
          logIn={this.logIn} resetPassword={this.resetPassword} withRegister={withRegister}
          emailInputOnChange={emailInputOnChange} passwordInputOnChange={passwordInputOnChange} onKeyDown={onKeyDown} closeAlert={closeAlert} />
    );
  }

  componentDidMount() {
    this.emailInputRef.current.focus();
    if (this.props.displayed) this.props.displayed();
  }

  componentDidUpdate() {
    if (this.props.displayed) this.props.displayed();
  }

}

export default Login;
