

import Call, { buildApiBasePath } from './Base';

const SessionPath = `${buildApiBasePath()}/session`;

export function loginAndCreateSession(hostname, email, password, extraAuthenticationData = {}) {
  const uri = `${SessionPath}/login-and-create-session`;
  const options = {
    uri,
    body: {
      hostname,
      email,
      password,
      extraAuthenticationData,
    }
  };

  return Call.post(uri, options);
}

export function validateTokenAndCreateSession(hostname, token) {
  const uri = `${SessionPath}/validate-token-and-create-session`;
  const options = {
    uri,
    body: {
      hostname,
      token
    }
  };

  return Call.post(uri, options);
}

export function revalidateSession(sessionId) {
  const uri = `${SessionPath}/revalidate-session`;
  const options = {
    uri,
    body: {
      sessionId
    }
  };

  return Call.post(uri, options);
}

export function resetPassword(hostname, email, linkHostname) {
  const uri = `${SessionPath}/reset-password`;
  const options = {
    uri,
    body: {
      hostname,
      email,
      linkHostname
    }
  };
  return Call.post(uri, options);
}

export function checkRecoverPasswordToken(hostname, recoverPasswordToken) {
  const uri = `${SessionPath}/check-reset-token`;
  const options = {
    uri,
    body: {
      hostname,
      recoverPasswordToken
    }
  };

  return Call.post(uri, options);
}

export function updatePasswordGroupedUser(hostname, recoverPasswordToken, newPasswordInput) {
  const uri = `${SessionPath}/change-password`;
  const options = {
    uri,
    body: {
      hostname,
      recoverPasswordToken,
      newPasswordInput
    }
  };

  return Call.post(uri, options);
}

export function addGroupedUser(hostname, user) {
  const uri = `${SessionPath}/add-grouped-user`;
  const options = {
    uri,
    body: {
      hostname,
      user
    }
  };

  return Call.post(uri, options);
}

export function checkEmailActivationToken(hostname, activateUserToken) {
  const uri = `${SessionPath}/check-activation-token`;
  const options = {
    uri,
    body: {
      hostname,
      activateUserToken
    }
  };

  return Call.post(uri, options);
}

export function activateGroupedUser(hostname, activateUserToken, password) {
  const uri = `${SessionPath}/activate-grouped-user`;
  const options = {
    uri,
    body: {
      hostname,
      activateUserToken,
      password
    }
  };

  return Call.post(uri, options);
}